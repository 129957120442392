.banner-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    margin: 8px;
    padding: 20px;
    border-radius: 3px;
    background-color: #1a475f;
    font-family: "Roboto", sans-serif;
    color: #dfebeb;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.react-select__control {
    background-color: #011328 !important;
    border: none !important;
}

.banner-form label {
    margin-bottom: 8px;
    font-weight: bold;
}

.react-select__control {
    border-radius: 0 !important;
}

.banner-form .react-select-container {
    margin-bottom: 16px;
}

.callsign-container {
    display: flex;
    gap: 10px;
}

.select-wrapper {
    flex: 1;
}

.react-select__control {
    width: 100%;
}

.time-container {
    display: flex;
    align-items: center;
}

.time-separator {
    margin: 0 10px;
}

.react-time-picker {
    width: 100%;
    border: none;
    font-size: 11pt;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    background-color: #011328;
    box-sizing: border-box;
    margin-bottom: 4px;
    padding: 2px 8px;
}

.react-time-picker__wrapper {
    border: none;
}

.react-datepicker-wrapper input, .candidate-input{
    width: 100%;
    border: none;
    font-size: 11pt;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    background-color: #011328;
    box-sizing: border-box;
    margin-bottom: 4px;
    align-items: center;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    font-size: 11pt;
    padding: 2px 8px;
    font-family: "Roboto", sans-serif;
    color: #dfebeb;
}

.react-select__input-container {
    color: #dfebeb !important;
}

.react-select__menu {
    background-color: hsl(226, 18%, 19%) !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4) !important;
}

.react-select__option--is-focused {
    background-color: #011328 !important;
}


/* Input text colors */
.react-select__single-value {
    color: #dfebeb !important; 
}
.react-select__placeholder {
    color: #484b4c !important;
}
.react-select__control--is-focused .react-select__input {
    color: #ffffff !important;
}
.react-select__option--is-selected {
    background-color: #000000 !important;
}
.react-select__option {
    color: #dfebeb !important;
}

/* Calendar container */
.react-datepicker__month-container {
    background-color: hsl(226, 18%, 19%) !important;
    color: #dfebeb !important;
    font-family: "Roboto", sans-serif;
}
.react-datepicker__header {
    background-color: hsl(226, 18%, 25%) !important;
}
.react-datepicker__day {
    color: #dfebeb !important;
    background-color: hsl(226, 18%, 19%) !important;
}
.react-datepicker__day--today {
    border: 1px solid #484b4c !important;
    border-radius: 4px !important;
}
.react-datepicker__current-month, .react-datepicker__day-name {
    color: #dfebeb !important;
}
.react-datepicker__day:hover {
    background-color: #011328 !important;
    font-weight: 600;
}

.banner-form button {
    padding: 10px 20px;
    border: none;
    background-color: #41826e;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 14px;
}

.banner-form button:hover {
    background-color: #305f51;
}

.react-datepicker__day--selected, .react-datepicker__day--selected:hover {
    background-color: #011328 !important;
    color: #fff !important;
    font-weight: 600;
}

.custom-callsign-wrapper {
    flex: 1;
    margin-top: 10px;
}

.custom-callsign-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

label {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 10pt;
    margin-bottom: 2px !important;
    margin-top: 14px;
    color: #dfebeb;
}

.input-error {
    border-color: #b63f3f;
}