.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #dfebeb;
}

.output-container h2, .output-container p {
  font-family: "Roboto", sans-serif;
  color: #dfebeb;
  user-select: text;
  max-width: 580px;
  width: 96%;
  background-color: #011328;
  padding: 4px 0px;
  margin-bottom: 18px;
  margin-top: 0px;
}

html, body {
  user-select: none;
}

a {
  color: #43c6e7;
}

.desc-container {
  position: relative;
  display: flex;
  align-items: center;
}

.copy-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #011328;
}

body {
  background-color: hsl(226, 18%, 19%);
  background-image: url(./pattern.svg);
  background-size: 900px;
  background-repeat: repeat;
  background-position: top left; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #011328;
  font-family: "Roboto", sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.button {
  padding: 10px 20px;
  border: none;
  background-color: #41826e;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 8px;
  font-family: "Roboto", sans-serif;
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  width: 200px;
}

.button:hover {
  background-color: #305f51;
}

.new-banner-button {
  padding: 10px 20px;
  border: none;
  background-color: #d58000;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 8px;
  font-family: "Roboto", sans-serif;
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  width: 200px;
}

.new-banner-button:hover {
  background-color: #a06000;
}

.banner-image {
  max-width: 580px;
  width: 96%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  margin-bottom: 8px;
}

.output-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  margin-top: 8px; 
  font-family: "Roboto", sans-serif;
}